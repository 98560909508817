<!-- 积分审核  -->
<template>
  <div class="">
    <a-menu v-model="activeTab" mode="horizontal">
      <a-menu-item key="1">待审核</a-menu-item>
      <a-menu-item key="2">全部</a-menu-item>
    </a-menu>
    <!-- table模块 -->
    <!-- 根据tab或者menu的切换只改变表格api的写法关键点就是表格的key要和activeTab的值一致 -->
    <ax-table
      :key="activeTab[0]"
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :showToolBar="false"
      :searchForm="searchForm"
      :dataSourceApi="dataSourceApis"
      @action-column-click="actionColumnClick"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="state" slot-scope="{ record }">
        <a-button
          :class="{
            gray: record.state === '2', //已驳回
            green: record.state === '1', //已通过
            orange: record.state === '0', //待审核
          }"
        >
          {{
            record.state === "0"
              ? "待审核"
              : record.state === "1"
              ? "已通过"
              : "已驳回"
          }}
        </a-button>
      </div>
      <template slot="points" slot-scope="{ record }">{{ record.points?record.points:'--' }}</template>
      <template slot="phone" slot-scope="{ record }">{{ record.phone?record.phone:'--' }}</template>
    </ax-table>
    <!-- 审核弹窗 -->
    <examine ref="examine" @refresh="refresh"></examine>
    <!-- 详情弹窗 -->
    <detaila ref="detaila" @refresh="refresh"></detaila>
  </div>
</template>

<script>
import examine from "./components/examine.vue";
import api from "./api";
import detaila from "./components/details.vue";

const searchForm = [
  {
    label: "申请人",
    type: "input",
    model: "residentName",
    options: {
      placeholder: "请输入",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "事项名称",
    type: "input",
    model: "matterName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "联系电话",
    type: "input",
    model: "phone",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "状态",
    type: "select",
    model: "state",
    options: { placeholder: "请选择", hide: true },
    formItem: {},
    col: { span: 6 },
  },
];

//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { examine, detaila },
  data() {
    //这里存放数据
    return {
      api,
      activeTab: ["1"],
      activeTabIndex: ["1"],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "申请人",
            dataIndex: "residentName",
            ellipsis: false,
            width: 80,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: false,
            width: 100,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 70,
          },
          {
            title: "事项名称",
            dataIndex: "matterName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "确定加分",
            dataIndex: "points",
            ellipsis: false,
            width: 100,
          },
          {
            title: "申请时间",
            dataIndex: "createTime",
            ellipsis: true,
            width: 120,
          },
          {
            title: "状态",
            dataIndex: "state",
            ellipsis: false,
            width: 100,
          },
        ],
        false,
        {
          // fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "审核",
                  name: "examine",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  disabled: record.state !== "0",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),

      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: {  },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    // tab切换
    activeTab(newValue) {
      if (newValue[0] === "1") {
        this.dataSourceApis = api.list;
        this.$refs.tableBox.$children[0].$refs.searchForm.setOptions(
          ["state"],
          "hide",
          true
        );
      } else if (newValue[0] === "2") {
        this.dataSourceApis = api.listAll;
        this.$refs.tableBox.$children[0].$refs.searchForm.setOptions(
          ["state"],
          "hide",
          false
        );
        this.getDictionsary();
      }
    },
  },
  //方法集合
  methods: {
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "上级网格",
                changeOnSelect: true,
              },
            }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 获取字典
    async getDictionsary() {
      // 审核状态
      await api.dictData({ dicKind: "integral_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "state",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 审核
        case "examine":
          this.$refs.examine.openModal(record);
          break;
        // 详情
        case "watch":
          this.$refs.detaila.openModal(record);
          break;
      }
    },
    refresh() {
      this.$refs.tableBox.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.$nextTick(() => {
      this.getgridTree();
    });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
.orange {
  background-color: #fdf7ec;
  color: #e6a23c;
  border: #fff8f0;
  border-radius: 4px;
}
.green {
  background-color: #f7ffea;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.gray {
  background-color: #e6e9ec;
  color: #000;
  border-radius: 4px;
  border: #e6e9ee;
}
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #2A5CFF !important;
  color: #2A5CFF !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #2A5CFF !important;
  color: #2A5CFF !important;
}
</style>
<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">人员信息</div>
        </div>
      </div>
      <ax-form ref="form" :formBuilder="formBuilder"></ax-form>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">事件信息</div>
        </div>
      </div>
      <ax-form ref="form1" :formBuilder="formBuilder1" @change="examineChange">
        <div slot="fileIds">
          <imagePreview
            ref="img1"
            :businessId="businessId"
            v-if="showPic"
          ></imagePreview>
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
          :loading="saveType"
          style="background-color: #556bff"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imagePreview from "@/pages/views/Acomponents/image-preview/imagePreview";
import api from "../api.js";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const formList = [
  {
    label: "申请人",
    type: "",
    model: "residentName",
    options: {},
    col: { span: 12 },
  },

  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: {},
    col: { span: 12 },
  },
  {
    label: "申请时间",
    type: "",
    model: "createTime",
    options: {},
    col: { span: 12 },
  },
  {
    label: "所属网格",
    type: "",
    model: "gridName",
    options: {},
    col: { span: 12 },
  },
];
const formList1 = [
  {
    label: "事件名称",
    type: "",
    model: "matterName",
    options: { disabled: true },
    col: { span: 24 },
  },

  {
    label: "事项描述",
    type: "",
    model: "matterDescrip",
    options: { disabled: true },
    col: { span: 24 },
  },
  {
    label: "图片描述",
    type: "",
    model: "fileIds",
    options: {},
    col: { span: 24 },
  },
  {
    label: "审核状态",
    type: "radio",
    model: "status",
    options: {
      placeholder: "请选择",
      options: [
        { label: "通过", value: "1" },
        { label: "拒绝", value: "2" },
      ],
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "驳回理由", //自动获取可修改
    type: "textarea",
    model: "description",
    options: {
      minRows: 3,
      placeholder: "请输入",
      maxLength: 11,
      hide: true,
    },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请输入",
      },
    ],
  },
  {
    label: "确定加分", //自动获取可修改
    type: "inputNumber",
    model: "points",
    options: { placeholder: "请输入", hide: true },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请输入",
      },
    ],
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { imagePreview },
  data() {
    //这里存放数据
    return {
      api,
      id: "",
      visible: false,
      saveType: false,
      showPic: false,
      businessId: "",
      title: "积分审核",
      info: {},
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 150, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.saveType = false;
      this.showPic = false;
      this.id = record.id;
      this.getPointsById(record.id);
    },
    // 根据id查详情
    async getPointsById(id) {
      try {
        const res = await api.getPointsById(id);
        this.showPic = true;
        this.businessId = res.data.id;
        this.$refs.form1.setFieldsValue(res.data);
        this.$refs.form.setFieldsValue(res.data);
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 根据审核意见显示不同的字段
    examineChange(e, type) {
      if (type === "status") {
        // 通过
        if (e == "1") {
          this.$refs.form1.setOptions(["description"], "hide", true);
          this.$refs.form1.setOptions(["points"], "hide", false);
        } else if (e == "2") {
          // 拒绝
          this.$refs.form1.setOptions(["description"], "hide", false);
          this.$refs.form1.setOptions(["points"], "hide", true);
        }
      }
    },
    submit() {
      this.$refs.form1.form.validateFields(async (err, value) => {

        if (err) return;
        this.saveType = true;
        try {
          let obj = {};
          if (value.status === "1") {
            obj = {
              id: this.id,
              status: value.status,
              points: value.points,
            };
          } else if (value.status === "2") {
            obj = {
              id: this.id,
              status: value.status,
              description: value.description,
            };
          }
          const res = await api.getPointsApplication(obj);
          if (res.status == 200) {
            this.$message.success('操作成功')
            this.closeModal();
            this.saveType = false
            this.$emit('refresh')
          }else{
            this.$message.error('操作失败')
            this.saveType = false
          }
        } catch (error) {
          console.error(error);
        }
      });
    },
    closeModal() {
      this.visible = false;
      this.$refs.form1.resetFields();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>